import { useQuery } from '@blitzjs/rpc';
import getActivePromocodes from 'app/promocodes/queries/getActivePromocodes';
import Link from 'next/link';
import { useRouter } from 'next/router';

const nth = (day: number) => {
    if (day > 3 && day < 21) return 'th';
    switch (day % 10) {
        case 1:
            return 'st';
        case 2:
            return 'nd';
        case 3:
            return 'rd';
        default:
            return 'th';
    }
};

const getMonthAndDay = (date: string) => {
    if (date) {
        const dateObj = new Date(fixFormateDate(date));
        return dateObj.toLocaleDateString('en-us', { month: 'long', day: 'numeric' });
    }
};

const fixFormateDate = (date: string) => {
    const arr = date.split('.').map((el) => (el[0] === '0' ? el.replace('0', '') : el));
    const day = Number(arr[0]);
    const month = Number(arr[1]) - 1;
    const fullYear = Number(arr[2].slice(0, 4));
    const hours = Number(arr[2].slice(5, 7));
    const minutes = Number(arr[2].slice(-2));

    const formattedDate = new Date();
    formattedDate.setDate(day);
    formattedDate.setMonth(month);
    formattedDate.setFullYear(fullYear);
    formattedDate.setHours(hours);
    formattedDate.setMinutes(minutes);

    return formattedDate;
};

interface PromocodesProps {
    className?: string;
}

const Promocodes = ({ className }: PromocodesProps) => {
    const router = useRouter();
    const isMultipassPage = router.pathname == '/multipass';

    const [promocodes] = useQuery(getActivePromocodes, {});
    const promocode = promocodes?.find((promocode) => !promocode.eventId);

    return promocode ? (
        <div className={`alert text-center ${className} `}>
            <svg
                className="icon icon-gift icon-start"
                xmlns="http://www.w3.org/2000/svg"
                fill="none"
                viewBox="0 0 24 24"
                stroke="currentColor"
            >
                <path
                    d="M12 8v13m0-13V6a2 2 0 112 2h-2zm0 0V5.5A2.5 2.5 0 109.5 8H12zm-7 4h14M5 12a2 2 0 110-4h14a2 2 0 110 4M5 12v7a2 2 0 002 2h10a2 2 0 002-2v-7"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    strokeWidth="2"
                />
            </svg>
            {`Take ${promocode.percent}% off everything. Use code `}
            <strong>{promocode.promocode}</strong>.{' '}
            {`Sale ends ${getMonthAndDay(promocode.endDate)}${nth(
                Number(promocode.endDate.slice(0, 2)),
            )} at midnight PDT.`}
            {!isMultipassPage && (
                <>
                    {' '}
                    <Link href="/multipass">Learn more</Link>
                </>
            )}
        </div>
    ) : null;
};

export default Promocodes;

import React from 'react';
import { StyledImage, StyledLoaderContainer } from './styled';

const Loader = () => {
    return (
        <StyledLoaderContainer>
            <StyledImage loading="lazy" src="/img/GN_Preloader_orange.gif" alt="loader" width={100} height={100} />
        </StyledLoaderContainer>
    );
};

export default Loader;

import { ContentCategory } from '@gn/core/type';
import { TagTab } from 'app/types';
import { Prisma, Tag, Content, User } from '@gn/db';

type ExtendedTag = Tag & { contents: (Content & { users: User[] })[] };
export function tagWithContentsCount(tag: ExtendedTag) {
    const contentsCount = tag.contents?.reduce(
        (result, content) => {
            result[TagTab.EXPERTS] += content.users?.length ?? 0;

            switch (content.category) {
                case ContentCategory.Talk:
                case ContentCategory.Video:
                    result[TagTab.TALKS] += 1;
                    break;
                case ContentCategory.Workshop:
                    result[TagTab.WORKSHOPS] += 1;
                    break;
                default:
                    break;
            }
            return result;
        },
        {
            [TagTab.EXPERTS]: 0,
            [TagTab.TALKS]: 0,
            [TagTab.WORKSHOPS]: 0,
        },
    );

    return { ...tag, contents: contentsCount ?? {} };
}

export const getTagSearchWhere = (searchTerm: string) =>
    ({
        OR: [
            {
                label: {
                    equals: searchTerm,
                    mode: 'insensitive',
                },
            },
            {
                synonyms: {
                    contains: searchTerm,
                    mode: 'insensitive',
                },
            },
        ],
    } as Prisma.TagWhereInput);

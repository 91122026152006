import Link from 'next/link';
import { Suspense } from 'react';
import cn from 'classnames';
import dynamic from 'next/dynamic';

const LogoSvg = dynamic(() => import('../icons/LogoSvg').then((module) => module), {
    ssr: false,
});
export default function Logo({ className }: { className?: string }) {
    return (
        <Link href="/" className={cn('logo', className)} aria-label="GitNation home page">
            <div style={{ width: '26px', height: '33px' }}>
                <LogoSvg width={26} height={33} />
            </div>

            <span>GitNation</span>
        </Link>
    );
}

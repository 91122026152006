import { useParams } from '@blitzjs/next';
import { useMutation } from '@blitzjs/rpc';
import { useSession } from '@blitzjs/auth';
import Link from 'next/link';
import { useContext, useEffect } from 'react';

import { NewContentCountResult } from '../../contents/queries/countUserNewContent';
import { ContentCategory } from '@gn/core/type';
import setUserLastSeenPage from '../../users/mutations/setUserLastSeenPage';
import { useNewContentCounter } from '../hooks/useNewContentCounter';
import { GetMultipassButton } from './buttons/multipass';
import { CurrentUserContext } from './CurrentUserContext';
import { FilterSection } from './filters/FilterSection';
import { BookmarkIcon } from './icons/BookmarkIcons';
import MasksIcon from './icons/MasksIcon';
import { NewContentCountNotification } from './NewContentCountNotification';
import { MenuTagsList } from './tags-list/MenuTagsList';
import AllTagsIcon from './icons/AllTagsIcon';
import HistoryIcon from './icons/HistoryIcon';
import UsersIcon from './icons/UsersIcon';
import MentorsIcon from './icons/MentorsIcon';
import Newsletter from './Newsletter';
import FlagIcon from './icons/FlagIcon';

export enum MenuItems {
    Dashboard,
    Events,
    Talks,
    Articles,
    Workshops,
    Multipass,
    People,
    Bookmarks,
    MyEvents,
    MyHistory,
    MyTeam,
    TagsAll,
    Mentors,
    RssFeeds,
}

export interface LeftMenuProps {
    selectedTag?: string;
    selectedMenuItem?: MenuItems;
    tags?: { label: string }[];
    filters?: React.ReactNode;
    children?: React.ReactNode;
}

function getMenuClassesById(menuItem: MenuItems, selectedMenuItem?: MenuItems) {
    return 'aside-menu__link' + (menuItem === selectedMenuItem ? ' active' : '');
}

export const LeftMenu: React.FC<LeftMenuProps> = ({ tags, selectedTag, selectedMenuItem, filters, children }) => {
    const params = useParams();
    const user = useContext(CurrentUserContext);
    const session = useSession();
    const userId = user?.id || session.userId;

    const [setUserLastSeenPageMutation] = useMutation(setUserLastSeenPage, {});

    if (process.env.MACHINE === 'production') {
        // eslint-disable-next-line react-hooks/rules-of-hooks
        useEffect(() => {
            if (userId) {
                setUserLastSeenPageMutation({
                    userId,
                    currentPage: selectedMenuItem ? MenuItems[selectedMenuItem] : undefined,
                    tagParams: params?.tagParams as string[] | undefined,
                });
            }
        }, [params?.tagParams, selectedMenuItem, setUserLastSeenPageMutation, userId]);
    }

    const { newContentByPageCategoryCounts } = useNewContentCounter(user) as NewContentCountResult;
    const anyStatusGroupSubscription =
        user &&
        (
            (user.ownedSubscriptions.length && user.ownedSubscriptions) ||
            (user.subscriptions.length && user.subscriptions) ||
            []
        ).find((s) => s.itemQuantity > 1);

    return (
        <aside className="wrap__aside hidden-md">
            <div className="aside-block ">
                <div className="aside-title">Main menu</div>
                <ul className="aside-menu">
                    <li className="aside-menu__item">
                        <Link
                            href={user ? '/' : '/'}
                            className={getMenuClassesById(MenuItems.Dashboard, selectedMenuItem)}
                        >
                            <svg className="icon icon-home">
                                <use xlinkHref="/img/sprite.svg?v3#home"></use>
                            </svg>
                            <span className="aside-menu__text">Discover</span>
                        </Link>
                    </li>
                    <li className="aside-menu__item">
                        <Link href="/events" className={getMenuClassesById(MenuItems.Events, selectedMenuItem)}>
                            <FlagIcon />
                            <span className="aside-menu__text">Events</span>
                        </Link>
                    </li>
                    <li className="aside-menu__item">
                        <Link href="/talks" className={getMenuClassesById(MenuItems.Talks, selectedMenuItem)}>
                            <svg className="h-6 w-6 icon icon-microphon">
                                <use xlinkHref="/img/sprite.svg?v3#microphon"></use>
                            </svg>
                            <span className="aside-menu__text">Talks</span>
                            <NewContentCountNotification
                                contentCountsByType={newContentByPageCategoryCounts}
                                contentType={ContentCategory.Talk}
                            />
                        </Link>
                    </li>
                    <li className="aside-menu__item">
                        <Link href="/workshops" className={getMenuClassesById(MenuItems.Workshops, selectedMenuItem)}>
                            <svg className="h-6 w-6 icon icon-cart-bar">
                                <use xlinkHref="/img/sprite.svg?v3#workshops"></use>
                            </svg>
                            <span className="aside-menu__text">Workshops</span>
                            <NewContentCountNotification
                                contentCountsByType={newContentByPageCategoryCounts}
                                contentType={ContentCategory.Workshop}
                            />
                        </Link>
                    </li>
                    <li className="aside-menu__item">
                        <Link href="/people" className={getMenuClassesById(MenuItems.People, selectedMenuItem)}>
                            <MasksIcon />
                            <span className="aside-menu__text">People</span>
                        </Link>
                    </li>
                    <li className="aside-menu__item">
                        <Link href="/mentors" className={getMenuClassesById(MenuItems.Mentors, selectedMenuItem)}>
                            <MentorsIcon />
                            <span className="aside-menu__text">Mentors</span>
                        </Link>
                    </li>
                    <li className="aside-menu__item">
                        <Link href="/articles" className={getMenuClassesById(MenuItems.Articles, selectedMenuItem)}>
                            <svg className="h-6 w-6 icon">
                                <use xlinkHref="/img/sprite.svg?v3#document-text"></use>
                            </svg>
                            <span className="aside-menu__text">Articles</span>
                            <NewContentCountNotification
                                contentCountsByType={newContentByPageCategoryCounts}
                                contentType={ContentCategory.Article}
                            />
                        </Link>
                    </li>
                    <li className="aside-menu__item">
                        <GetMultipassButton className={getMenuClassesById(MenuItems.Multipass, selectedMenuItem)}>
                            <span className="aside-menu__text">Multipass</span>
                        </GetMultipassButton>
                    </li>
                    {!!user && !!user.bookmarks?.length && (
                        <li className="aside-menu__item">
                            <Link
                                href={'/my-bookmarks'}
                                className={getMenuClassesById(MenuItems.Bookmarks, selectedMenuItem)}
                            >
                                <BookmarkIcon />
                                <span className="aside-menu__text">My Bookmarks</span>
                            </Link>
                        </li>
                    )}
                    {!!user && (
                        <>
                            <li className="aside-menu__item">
                                <Link
                                    href={'/my-history'}
                                    className={getMenuClassesById(MenuItems.MyHistory, selectedMenuItem)}
                                >
                                    <HistoryIcon />
                                    <span className="aside-menu__text">My History</span>
                                </Link>
                            </li>

                            {anyStatusGroupSubscription && (
                                <li className="aside-menu__item">
                                    <Link
                                        href={'/my-team'}
                                        className={getMenuClassesById(MenuItems.MyTeam, selectedMenuItem)}
                                    >
                                        <UsersIcon />
                                        <span className="aside-menu__text">My Team</span>
                                    </Link>
                                </li>
                            )}
                        </>
                    )}

                    <li className="aside-menu__item">
                        <Link href={'/tags'} className={getMenuClassesById(MenuItems.TagsAll, selectedMenuItem)}>
                            <AllTagsIcon />
                            <span className="aside-menu__text">All tags</span>
                        </Link>
                    </li>
                </ul>
            </div>
            <FilterSection>{filters}</FilterSection>
            <MenuTagsList tags={tags} selectedTag={selectedTag} />
            <Newsletter />
            {children}
        </aside>
    );
};

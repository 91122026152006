import Link from 'next/link';
import { trackEvent } from 'app/analytics';
import { MultipassIcon } from '../../icons/MultipassIcon';

interface GetMultipassButtonProps {
    className?: string;
    iconClassName?: string;
    onClick?: () => void;
    children?: React.ReactNode;
}

export const GetMultipassButton: React.FC<GetMultipassButtonProps> = ({
    className = 'btn',
    iconClassName,
    onClick,
    children,
}) => {
    return (
        <Link href="/multipass" className={className} onClick={onClick}>
            <MultipassIcon className={iconClassName} />
            {children ?? 'Get your Multipass'}
        </Link>
    );
};

interface RenewMultipassButtonProps {
    withIcon?: boolean;
    className?: string;
    iconClassName?: string;
    children?: React.ReactNode;
}

export const RenewMultipassButton: React.FC<RenewMultipassButtonProps> = ({
    withIcon = false,
    className = 'btn',
    iconClassName,
    children,
}) => {
    return (
        <Link
            href="/multipass"
            className={className}
            onClick={() => {
                trackEvent({
                    action: 'click-navigate-multipass-event-renewal',
                });
            }}
        >
            {withIcon && <MultipassIcon className={iconClassName} />}
            {children ?? 'Renew Multipass'}
        </Link>
    );
};

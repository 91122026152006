import cn from 'classnames';

interface MentorsIconProps {
    className?: string;
}

export default function MentorsIcon({ className }: MentorsIconProps) {
    return (
        <svg
            className={cn('icon icon-mentors', className)}
            width="26"
            height="18"
            viewBox="0 0 26 18"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
        >
            <path d="M16.2298 14.1617C15.8754 13.6891 15.2059 13.6104 14.7333 14.0042C14.2607 14.398 14.182 15.0281 14.5758 15.5007C15.0484 16.0914 15.6785 16.6821 16.4661 17.0759C20.2467 19.0056 23.6335 15.9733 24.8937 12.0745C25.7601 9.39658 26.0358 6.28545 25.9964 3.41062C26.0358 2.89866 25.6026 2.46547 25.2087 2.3867C24.5393 2.15041 20.3648 0.614543 19.5378 0.141967C19.1046 -0.133702 18.5533 0.0632044 18.4352 0.141967C17.8838 0.417636 14.7333 1.95351 12.7642 2.42608C12.4098 2.50485 11.8585 2.8199 11.9766 3.56814C11.9766 3.76505 11.9766 3.96196 11.9766 4.15886C11.9766 6.16731 11.7403 9.12091 10.9133 11.5625C9.18055 16.7215 5.12427 17.0366 3.27335 11.8382C2.44634 9.59348 2.09191 6.67927 2.13129 4.27701C3.31273 3.84381 5.71499 2.93804 7.09334 2.26856C7.48715 2.46547 7.99911 2.70175 8.58983 2.93804C9.14117 3.17433 9.73189 2.89866 9.96817 2.34732C10.2045 1.79598 9.92879 1.20526 9.37745 0.968976C8.58983 0.653925 7.84158 0.299493 7.64468 0.181349C7.32963 -0.0155574 6.81767 -0.0155583 6.542 0.141967C5.2818 0.850831 1.30428 2.30794 0.792328 2.50485C0.359133 2.62299 0.0834641 3.0168 0.0440828 3.45C-0.152824 6.28545 0.319752 9.90853 1.2649 12.5471C3.82469 19.6751 10.5195 19.3207 12.9218 12.232C13.8275 9.5541 14.1032 6.4036 14.1032 4.23763C15.8754 3.68629 18.0413 2.66237 18.9865 2.22918C20.2073 2.8199 22.6883 3.72567 23.9092 4.15886C23.8698 6.08855 23.6729 9.00277 22.8852 11.4444C22.0976 13.9254 20.0104 16.4852 17.4112 15.1856C16.9387 14.91 16.5449 14.5555 16.2298 14.1617Z" />
            <path d="M16.7402 5.30103C17.8428 5.30103 18.7486 6.2068 18.7486 7.30947C18.7486 8.41215 17.8428 9.31792 16.7402 9.31792C15.6375 9.31792 14.7317 8.41215 14.7317 7.30947C14.6923 6.2068 15.5981 5.30103 16.7402 5.30103ZM16.7402 6.99442C16.5432 6.99442 16.3857 7.15195 16.3857 7.34885C16.3857 7.54576 16.5432 7.70329 16.7402 7.70329C16.9371 7.70329 17.0946 7.54576 17.0946 7.34885C17.0946 7.15195 16.9371 6.99442 16.7402 6.99442Z" />
            <path d="M20.0106 6.48242C19.538 6.48242 19.1836 6.87624 19.1836 7.30943C19.1836 7.74263 19.5774 8.13644 20.0106 8.13644H22.2947C22.7673 8.13644 23.1217 7.74263 23.1217 7.30943C23.1217 6.87624 22.7279 6.48242 22.2947 6.48242H20.0106Z" />
            <path d="M9.13974 5.18267C8.82469 4.86762 8.27335 4.82824 7.9583 5.18267C7.64325 5.49772 7.60387 6.04906 7.9583 6.36411L8.62779 7.03359L7.9583 7.70307C7.64325 8.01813 7.64325 8.56946 7.9583 8.88451C8.27335 9.19956 8.82469 9.19956 9.13974 8.88451L10.3212 7.66369L10.3606 7.62431C10.6756 7.30926 10.715 6.75792 10.3606 6.44287L9.13974 5.18267Z" />
            <path d="M5.12412 5.18267C4.80907 4.86762 4.25773 4.82824 3.94268 5.18267C3.62763 5.49772 3.58825 6.04906 3.94268 6.36411L4.61216 7.03359L3.94268 7.70307C3.62763 8.01813 3.62763 8.56946 3.94268 8.88451C4.25773 9.19956 4.80907 9.19956 5.12412 8.88451L6.30556 7.66369L6.34494 7.62431C6.65999 7.30926 6.69937 6.75792 6.34494 6.44287L5.12412 5.18267Z" />
            <path d="M6.11035 11.5233C5.9922 11.0901 5.55901 10.8144 5.08643 10.8932C4.61386 10.9719 4.37757 11.4445 4.45633 11.9171C4.57448 12.4684 4.88953 12.941 5.32272 13.256C5.75592 13.5711 6.26787 13.768 6.81921 13.768C7.37055 13.768 7.92189 13.5711 8.3157 13.256C8.7489 12.941 9.06395 12.4684 9.18209 11.9171C9.30023 11.4839 9.02457 11.0113 8.59137 10.8932C8.15818 10.775 7.6856 11.0507 7.56746 11.4839C7.52808 11.6414 7.40993 11.7989 7.29179 11.9171C7.17364 12.0352 6.97674 12.0746 6.81921 12.0746C6.6223 12.0746 6.46478 11.9958 6.34664 11.9171C6.26787 11.8383 6.14973 11.7202 6.11035 11.5233Z" />
            <path d="M18.0405 11.5233C17.9223 11.0901 17.4891 10.8144 17.0166 10.8932C16.5834 11.0113 16.3077 11.4445 16.3865 11.9171C16.5046 12.4684 16.8197 12.941 17.2528 13.256C17.686 13.5711 18.198 13.768 18.7493 13.768C19.3007 13.768 19.852 13.5711 20.2458 13.256C20.679 12.941 20.9941 12.4684 21.1122 11.9171C21.2304 11.4839 20.9547 11.0113 20.5215 10.8932C20.0883 10.775 19.6157 11.0507 19.4976 11.4839C19.4582 11.6414 19.3401 11.7989 19.2219 11.9171C19.1038 12.0352 18.9069 12.0746 18.7493 12.0746C18.5524 12.0746 18.3949 11.9958 18.2768 11.9171C18.198 11.8383 18.0799 11.7202 18.0405 11.5233Z" />
        </svg>
    );
}

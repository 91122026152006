import { useSession } from '@blitzjs/auth';
import { useQuery } from '@blitzjs/rpc';
import getUserEventRegistrations from 'app/users/queries/getUserEventRegistrations';
import { PromiseReturnType } from 'blitz';
import { useContext } from 'react';
import { CurrentUserContext } from '../components/CurrentUserContext';

type Registrations = PromiseReturnType<typeof getUserEventRegistrations>;
type Registration = Registrations[number];

export default function useEventRegistration(eventId: number) {
    const currentUser = useContext(CurrentUserContext);
    const { userId } = useSession();
    const [registrations = [], { refetch, isLoading }] = useQuery(
        getUserEventRegistrations,
        {},
        {
            enabled: !!userId || !!currentUser,
            staleTime: 2 * 60 * 1000,
        },
    );

    const registrationsById = registrations.reduce(
        (acc, curr) => (acc.set(curr.eventId, curr), acc),
        new Map<number, Registration>(),
    );

    return {
        registration: registrationsById.get(eventId) ?? null,
        refetch,
        isLoading,
    };
}

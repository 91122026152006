import Link from 'next/link';
import { SubscribeIconButton } from '../SubscribeTagButton';
import { TagTab } from 'app/types';
import { PromiseReturnType } from 'blitz';
import getTagsWithContentCount from 'app/tags/queries/getTagsWithContentCount';

const getClassRandom = (id: number) => {
    return (id % 12) + 1;
};

const TagListItem = ({
    tag,
    className,
}: {
    tag: NonNullable<PromiseReturnType<typeof getTagsWithContentCount>>[0];
    className?: string;
}) => {
    return (
        <div className="tags-wrapper__item col-xxl-3 col-xl-4 col-lg-6 col-md-4 col-6 ">
            <div className={`tag-box tag-box--st${getClassRandom(tag.id)} ${className || ''}`}>
                <Link href={`/tags/${tag.slug}`} className="tag-box__link">
                    <div className="tag-box__title">
                        <span className="tag-box__title-inner ttl">
                            #{tag.label}
                            <SubscribeIconButton tag={tag} />
                        </span>
                    </div>
                    <div className="tag-box__descr">{tag.description}</div>
                </Link>
                <nav className="tag-box__details tb-details">
                    {!!tag.contents[TagTab.TALKS] && (
                        <Link href={`/tags/${tag.slug}`} className="tb-details__item tb-details__link">
                            <span>Talks: </span>
                            <strong>{tag.contents[TagTab.TALKS]}</strong>
                        </Link>
                    )}
                    {!!tag.contents[TagTab.WORKSHOPS] && (
                        <Link
                            href={`/tags/${tag.slug}?filter=${TagTab.WORKSHOPS}`}
                            className="tb-details__item tb-details__link"
                        >
                            <span>Workshops: </span>
                            <strong>{tag.contents[TagTab.WORKSHOPS]}</strong>
                        </Link>
                    )}
                </nav>
            </div>
        </div>
    );
};

export default TagListItem;

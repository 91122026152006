import { useQuery } from '@blitzjs/rpc';
import { useSession } from '@blitzjs/auth';
import countUserNewContent, { NewContentCountResult } from 'app/contents/queries/countUserNewContent';
import { ContextUser } from '../components/CurrentUserContext';

export const useNewContentCounter = (user?: ContextUser): NewContentCountResult => {
    const session = useSession();
    const [contentCountsData] = useQuery(countUserNewContent, {}, { enabled: !!session.userId || !!user });

    if (contentCountsData) {
        return {
            newContentByPageCategoryCounts: contentCountsData.newContentByPageCategoryCounts,
            newContentByTagsIdCounts: contentCountsData.newContentByTagsIdCounts,
        };
    }
    return {
        newContentByPageCategoryCounts: {},
        newContentByTagsIdCounts: {},
    };
};

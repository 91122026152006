import { useMutation } from '@blitzjs/rpc';
import { ErrorBoundary } from '@blitzjs/next';
import { useContext, Suspense } from 'react';
import Link from 'next/link';
import { getEventLink, isStreamPageAvailable } from 'app/events/utils';
import createEventSubscription from 'app/notification-subscriptions/mutations/createEventSubscription';
import { getBadgeUrl } from 'app/badges/utils';
import { EventWithBrand } from '@gn/core/type';
import useAuthenticated from '../hooks/useAuthenticated';
import useEventRegistration from '../hooks/useEventRegistration';
import useEventAccess from '../hooks/useEventAccess';
import { CurrentUserContext, setUser } from './CurrentUserContext';
import { DoneIcon } from './icons/DoneIcon';
import TicketIcon from './icons/TicketIcon';
import { RenewMultipassButton } from './buttons/multipass';
import { trackEvent } from 'app/analytics';
import ExclamationTriangleIcon from './icons/ExclamationTriangleIcon';

interface ButtonProps {
    event: EventWithBrand;
    brandDomainUrl?: boolean;
}

function UpdatesSignupButton({ event }: ButtonProps) {
    const { id: eventId } = event;

    const user = useContext(CurrentUserContext);

    const [createEventSubscriptionMutation] = useMutation(createEventSubscription, {
        onSuccess: async (data) => {
            setUser(
                {
                    ...user,
                    notificationsSubscriptions: [...(user?.notificationsSubscriptions ?? []), data],
                },
                { refetch: false },
            );
        },
    });

    const onClickAuthenticated = useAuthenticated(async (event) => {
        trackEvent({
            action: 'click-subscribe-to-event-reg-opening',
        });
        event?.preventDefault();
        await createEventSubscriptionMutation({ eventId: eventId });
    }, '/events');

    const subscription = user?.notificationsSubscriptions?.find(
        (notificationSubscription) => notificationSubscription.eventId === eventId,
    );
    return subscription ? (
        <div className="card-event__foot">
            <div className="card-event__foot-txt">
                <div className="txt-icon">
                    <DoneIcon />
                </div>
                Subscribed to registration open notification
            </div>
        </div>
    ) : (
        <div className="card-event__foot">
            <div className="card-event__access">
                <button
                    className="btn btn--default"
                    onClick={onClickAuthenticated}
                    title="Get email notification when event registration opens."
                >
                    Sign up for updates
                </button>
            </div>
        </div>
    );
}

function RegisterButton({ event, brandDomainUrl }: ButtonProps) {
    const user = useContext(CurrentUserContext);
    const { registration } = useEventRegistration(event.id);

    return registration && registration.status !== 'Canceled' && user ? (
        <Link href={getBadgeUrl(user, event)} target="_blank" className="btn btn--default">
            <TicketIcon className="icon-sm btn__icon-start" />
            My registration
        </Link>
    ) : (
        <DetailsLink event={event} brandDomainUrl={brandDomainUrl} />
    );
}

function DetailsLink({ event, brandDomainUrl }: ButtonProps) {
    return (
        <Link
            href={brandDomainUrl ? event.brand.domain! : getEventLink(event)}
            className="btn btn--default"
            target={brandDomainUrl ? '_blank' : undefined}
        >
            Details
        </Link>
    );
}

type EventLabelWIthRegProps = {
    straightToBuyModal?: boolean;
    event: EventWithBrand;
    brandDomainUrl?: boolean;
};

export function EventLabelWithReg({ event, straightToBuyModal, brandDomainUrl }: EventLabelWIthRegProps) {
    const { registration } = useEventRegistration(event.id);
    const { hasAccess, reason } = useEventAccess(event, event.startDate);

    switch (true) {
        case !event.isRegistrationOpen: {
            return <UpdatesSignupButton event={event} />;
        }
        case (hasAccess || registration?.status === 'Completed') && isStreamPageAvailable(event): {
            return (
                <div className="card-event__foot">
                    <a className="btn" target="_blank" href={`/events/${event.slug ?? event.id}/watch`}>
                        Stream Page
                    </a>
                </div>
            );
        }
        case !hasAccess && reason === 'multipass_expires': {
            return (
                <div className="card-event__foot">
                    <RenewMultipassButton />
                </div>
            );
        }
        default: {
            const fallback = (
                <div className="card-event__foot">
                    <DetailsLink event={event} brandDomainUrl={brandDomainUrl} />
                </div>
            );

            return (
                <ErrorBoundary fallback={fallback}>
                    <div className="card-event__foot">
                        <RegisterButton event={event} brandDomainUrl={brandDomainUrl} />
                    </div>
                </ErrorBoundary>
            );
        }
    }
}

import { Mentorship } from '@gn/db/client';
import { Subscription } from 'chargebee-typescript/lib/resources';
import { Content, Prisma, User, Tag, NotificationSubscription, UserOnBookmark } from '@gn/db';

export interface LocationData {
    name: string;
    count: number;
    difference?: number;
    lng?: number;
    lat?: number;
}

export interface OwnedSubscriptionExtended {
    status: string;
    itemQuantity: number;
    id: string;
    users: User[];
    startedAt: Date | null;
    currentTermStart: Date | null;
    currentTermEnd: Date | null;
}
export interface MailingSettingsData {
    monthlyContentDigest: boolean;
    speakerEmails: boolean;
    eventsAnnouncements: boolean;
}

export interface UserWithSubscriptionExtended
    extends Omit<
        User,
        | 'hashedPassword'
        | 'createdAt'
        | 'updatedAt'
        | 'approved'
        | 'client'
        | 'sessions'
        | 'contents'
        | 'interests'
        | 'areInterestsSet'
        | 'tokens'
        | 'EventRegistration'
        | 'subscriptions'
        | 'subscriberId'
        | 'twitterFollowers'
    > {
    subscriptions?: { status: string; id: string }[];
    ownedSubscriptions?: OwnedSubscriptionExtended[];
    ticketGroups?: { groupName: string | null; purchaseDate: Date | null }[];
    bookmarks?: UserOnBookmark[];
    mailingSettings: Prisma.JsonObject;
}

export interface UserWithMailingSettings
    extends Omit<
        User,
        | 'hashedPassword'
        | 'createdAt'
        | 'updatedAt'
        | 'email'
        | 'approved'
        | 'client'
        | 'sessions'
        | 'contents'
        | 'interests'
        | 'areInterestsSet'
        | 'tokens'
        | 'EventRegistration'
        | 'subscriberId'
        | 'twitterFollowers'
    > {
    mailingSettings: Prisma.JsonObject;
    subscriptions?: Pick<Subscription, 'status' | 'id'>[];
}

export interface UserWithBookmarks
    extends Omit<
        User,
        | 'hashedPassword'
        | 'createdAt'
        | 'updatedAt'
        | 'approved'
        | 'client'
        | 'sessions'
        | 'contents'
        | 'interests'
        | 'areInterestsSet'
        | 'tokens'
        | 'EventRegistration'
        | 'subscriptions'
        | 'subscriberId'
        | 'twitterFollowers'
    > {
    bookmarks?: UserOnBookmark[];
}

export interface UserWithInterestsAndBookmarks
    extends Omit<
        User,
        | 'hashedPassword'
        | 'createdAt'
        | 'updatedAt'
        | 'approved'
        | 'client'
        | 'sessions'
        | 'contents'
        | 'areInterestsSet'
        | 'tokens'
        | 'EventRegistration'
        | 'subscriptions'
        | 'subscriberId'
        | 'twitterFollowers'
    > {
    bookmarks?: UserOnBookmark[];
    interests?: Tag[];
}

export interface UserWithNotificationsSubscriptions
    extends Omit<
        User,
        | 'hashedPassword'
        | 'createdAt'
        | 'updatedAt'
        | 'approved'
        | 'client'
        | 'sessions'
        | 'contents'
        | 'interests'
        | 'areInterestsSet'
        | 'tokens'
        | 'EventRegistration'
        | 'subscriptions'
        | 'subscriberId'
    > {
    notificationsSubscriptions: NotificationSubscription[];
}

export enum TagTab {
    TALKS = 'talks',
    WORKSHOPS = 'workshops',
    EXPERTS = 'experts',
}

export enum ProfileTabs {
    CERTIFICATES = 'certificates',
}

export enum EventsTab {
    FUTURE = 'Upcoming events',
    PAST = 'Past events',
    JS = 'JavaScript events',
    REACT = 'React events',
    MY = 'My events',
}

export enum EventsBreadcrumbs {
    UPCOMING = 'Upcoming Tech Conferences',
    JS = 'JavaScript Conferences',
    REACT = 'React Conferences',
    PAST = 'IT Conferences Archive',
}

export type UserWithContent = Partial<User> & { contents: Partial<Content>[] } & { mentorship?: Partial<Mentorship> };

export type UserOnBookmarkIdOnly = Pick<UserOnBookmark, 'contentId'>;

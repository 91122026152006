import { useRouter } from 'next/router';
import React, { useContext, useEffect } from 'react';
import { CurrentUserContext } from './CurrentUserContext';
import { Tag } from '@gn/db/client';
import PlusIcon from './icons/PlusIcon';
import SubscribeIcon from './icons/SubscribeIcon';
import { trackEvent } from 'app/analytics';
import { ToggleTagSubscriptionContext } from './tags-list/ToggleTagSubscriptionContext';

export const SubscribeButton = ({ tag }) => {
    const router = useRouter();
    const currentUser = React.useContext(CurrentUserContext);
    const toggleTagSubscription = React.useContext(ToggleTagSubscriptionContext);

    useQuerySubscribe(tag);

    if (!toggleTagSubscription) {
        return null;
    }

    if (!currentUser) {
        return (
            <a
                href="#"
                onClick={(e) => {
                    e.preventDefault();
                    router.push({
                        pathname: '/login',
                        query: { next: router.asPath + `?subscribe=${tag.id}` },
                    });
                }}
                className="btn btn--default"
            >
                Subscribe
            </a>
        );
    } else {
        // @ts-ignore
        const interestsIds = currentUser.interests?.map((tag) => tag.id);
        if (interestsIds?.includes(tag.id)) {
            return (
                <a
                    href="#"
                    onClick={(e) => {
                        e.preventDefault();
                        trackEvent({
                            action: 'click-unsubscribe-from-tag',
                        });
                        toggleTagSubscription(tag, currentUser);
                    }}
                    className="btn btn--default"
                >
                    Unsubscribe
                </a>
            );
        } else {
            return (
                <a
                    href="#"
                    onClick={(e) => {
                        e.preventDefault();
                        trackEvent({
                            action: 'click-subscribe-to-tag',
                        });
                        toggleTagSubscription(tag, currentUser);
                    }}
                    className="btn btn--default"
                >
                    Subscribe
                </a>
            );
        }
    }
};

export const SubscribeIconButton = ({ tag }) => {
    const router = useRouter();
    const currentUser = React.useContext(CurrentUserContext);
    const toggleTagSubscription = React.useContext(ToggleTagSubscriptionContext);

    useQuerySubscribe(tag);

    if (!toggleTagSubscription) {
        return null;
    }

    if (!currentUser) {
        return (
            <button
                onClick={(e) => {
                    e.preventDefault();
                    e.stopPropagation();
                    router.push({
                        pathname: '/login',
                        query: { next: router.asPath + `?subscribe=${tag.id}` },
                    });
                }}
                className="tag-box__btn tb-join"
            >
                <PlusIcon />
            </button>
        );
    } else {
        // @ts-ignore
        const interestsIds = currentUser.interests?.map((tag) => tag.id);
        if (interestsIds?.includes(tag.id)) {
            return (
                <button
                    onClick={(e) => {
                        e.preventDefault();
                        e.stopPropagation();
                        trackEvent({
                            action: 'click-unsubscribe-from-tag-icon',
                        });
                        toggleTagSubscription(tag, currentUser);
                    }}
                    className="tag-box__btn tb-join _active"
                >
                    <SubscribeIcon />
                </button>
            );
        } else {
            return (
                <button
                    onClick={(e) => {
                        e.preventDefault();
                        e.stopPropagation();
                        trackEvent({
                            action: 'click-subscribe-to-tag-icon',
                        });
                        toggleTagSubscription(tag, currentUser);
                    }}
                    className="tag-box__btn tb-join"
                >
                    <PlusIcon />
                </button>
            );
        }
    }
};

const useQuerySubscribe = (tag: Pick<Tag, 'id'>) => {
    const router = useRouter();
    const currentUser = useContext(CurrentUserContext);
    const toggleTagSubscription = React.useContext(ToggleTagSubscriptionContext);

    const handleRedirect = async () => {
        await router.replace(
            {
                pathname: window.location.pathname,
                query: {},
            },
            undefined,
            { shallow: true },
        );
    };

    const handleSubscribe = async () => {
        if (toggleTagSubscription) {
            await toggleTagSubscription(tag, currentUser, false);
        }
        await handleRedirect();
    };

    useEffect(() => {
        if (!currentUser || typeof router.query.subscribe !== 'string' || Number(router.query.subscribe) !== tag.id) {
            return;
        }

        if (currentUser.interests.some((interest) => interest.id === tag.id)) {
            handleRedirect();
        } else {
            handleSubscribe();
        }
    }, [router.query.subscribe, currentUser, tag.id]);
    return null;
};

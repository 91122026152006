import styled from 'styled-components';

export const StyledLoaderContainer = styled.div`
    width: 100px;
    height: 100px;
    display: flex;
    align-content: center;
    justify-content: center;
    margin: 100px auto;
`;

export const StyledImage = styled.img`
    width: 100px;
    height: 100px;
`;

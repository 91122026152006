import { trackEvent } from 'app/analytics';

const Newsletter = () => {
    return (
        <div className="aside-block">
            <div className="aside-title">Newsletter</div>

            <p className="size-sm">
                Do not miss a deal from us. Get new talks, workshops, and free or discounted conference tickets
            </p>
            <form action="/newsletter-preferences" method="GET">
                <div className="form-field form-field--sm">
                    <input
                        type="email"
                        name="email"
                        className="form-input form-input--sm"
                        placeholder="Enter email"
                        required
                    />
                </div>
                <button
                    type="submit"
                    className="btn btn--block btn--default btn--sm"
                    onClick={() => {
                        trackEvent({
                            action: 'click-subscribe-newsletter',
                        });
                    }}
                >
                    Subscribe
                </button>
            </form>
        </div>
    );
};
export default Newsletter;

import InstagramIcon from './icons/InstagramIcon';
import TikTokIcon from './icons/TikTokIcon';
import TwitterIcon from './icons/TwitterIcon';
import Linkedin from './icons/linkedinIcon';
import cn from 'classnames';

export default function Socials({ type }: { type?: 'circle' }) {
    return (
        <div className={cn('social', { 'social--circle': type === 'circle' })}>
            <a
                target="_blank"
                arial-label="linkedin"
                href="https://www.linkedin.com/company/gitnation/"
                className="social__link"
            >
                <Linkedin />
            </a>
            <a target="_blank" arial-label="twitter" href="https://twitter.com/GitNationOrg/" className="social__link">
                <TwitterIcon />
            </a>
            <a
                target="_blank"
                arial-label="instagram"
                href="https://www.instagram.com/gitnation/"
                className="social__link"
            >
                <InstagramIcon />
            </a>
            <a
                target="_blank"
                arial-label="tiktok"
                href="https://www.tiktok.com/@gitnationorg/"
                className="social__link"
            >
                <TikTokIcon />
            </a>
        </div>
    );
}

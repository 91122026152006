export default function PlanetIcon() {
    return (
        <svg
            className="icon icon-planet"
            xmlns="http://www.w3.org/2000/svg"
            width="16"
            height="16"
            viewBox="0 0 16 16"
            fill="none"
        >
            <path
                d="M1.2912 7.25H2.75C3.57843 7.25 4.25 7.92157 4.25 8.75V9.5C4.25 10.3284 4.92157 11 5.75 11C6.57843 11 7.25 11.6716 7.25 12.5V14.7088M5 1.95164V3.125C5 4.16053 5.83947 5 6.875 5H7.25C8.07843 5 8.75 5.67157 8.75 6.5C8.75 7.32843 9.42157 8 10.25 8C11.0784 8 11.75 7.32843 11.75 6.5C11.75 5.67157 12.4216 5 13.25 5L14.0484 5M10.25 14.3659V12.5C10.25 11.6716 10.9216 11 11.75 11H14.0484M14.75 8C14.75 11.7279 11.7279 14.75 8 14.75C4.27208 14.75 1.25 11.7279 1.25 8C1.25 4.27208 4.27208 1.25 8 1.25C11.7279 1.25 14.75 4.27208 14.75 8Z"
                strokeWidth="2"
                strokeLinecap="round"
                strokeLinejoin="round"
            />
        </svg>
    );
}

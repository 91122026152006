import { useContext, useEffect } from 'react';
import { isMultipassValid } from '@gn/core/multipass/utils';
import { AttendanceFormat } from '@gn/db/client';
import { GAUserConfig, setUserData } from 'app/analytics';
import { CurrentUserContext } from './CurrentUserContext';

const GAUserSetting = () => {
    const user = useContext(CurrentUserContext);

    if (process.env.MACHINE === 'production') {
        // eslint-disable-next-line react-hooks/rules-of-hooks
        useEffect(() => {
            const data: GAUserConfig = {
                activeMPHolder: false,
                IRLTicketHolder: false,
                teamMPHolder: false,
            };
            if (user) {
                data.userId = user.id;
                data.activeMPHolder = isMultipassValid(user);
                data.IRLTicketHolder = !!user.ticketGroups?.some(
                    (ticketGroup) =>
                        ticketGroup.format === AttendanceFormat.InPerson ||
                        ticketGroup.format === AttendanceFormat.Hybrid,
                );
                data.teamMPHolder = !!user.activeOwnedSubscription && user.activeOwnedSubscription.itemQuantity > 1;
            }
            setUserData(data);
        }, [user]);
    }

    return null;
};

export default GAUserSetting;

import { useRouter } from 'next/router';
import { useEffect, useState } from 'react';

export function HeaderSearch() {
    const router = useRouter();
    const [searchTerm, setSearchTerm] = useState('');

    const onSubmit = (searchTerm: string) => {
        if (!searchTerm) {
            const { query } = router;
            delete query['clientSearchTerm'];
            // go to the page we were on before using the search
            router.push({ pathname: router.pathname, query }, undefined, {
                shallow: true,
            });
            return;
        }

        if (searchTerm.length < 2) {
            return;
        }

        router.push(
            {
                pathname: '/search/[searchTerm]',
                query: {
                    ...router.query,
                    clientSearchTerm: searchTerm,
                },
            },
            `/search/${searchTerm}`,
            { shallow: true },
        );
    };

    useEffect(() => {
        setSearchTerm((searchTerm) => {
            if (router.query.clientSearchTerm && router.query.clientSearchTerm === searchTerm) {
                return searchTerm;
            }
            return '';
        });
    }, [router.pathname, router.query.clientSearchTerm]);

    return (
        <div className="header__search">
            <form
                action="#"
                className="search-top"
                onSubmit={(e) => {
                    e.preventDefault();
                    onSubmit(searchTerm);
                }}
            >
                <input
                    value={searchTerm}
                    onChange={(event) => setSearchTerm(event.target.value)}
                    type="text"
                    placeholder="Search..."
                    className="search-top__input"
                />
                <svg className="icon icon-zoom">
                    <use xlinkHref="/img/sprite.svg?v3#zoom" />
                </svg>
            </form>
        </div>
    );
}

import { PORTAL_ORIGIN } from '@gn/core/config';

type QueryParamsProps = {
    [key: string]: string | number | boolean;
};

export enum URL {
    HOME = 'HOME',
    CREATE_PASSWORD = 'CREATE_PASSWORD',
    FORGOT_PASSWORD = 'FORGOT_PASSWORD',
    RESET_PASSWORD = 'RESET_PASSWORD',
    SIGN_UP = 'SIGN_UP',
    EMAIL_MIGRATION = 'EMAIL_MIGRATION',
}

export const appOrigin =
    process.env.IS_PULL_REQUEST === 'true'
        ? process.env.RENDER_EXTERNAL_URL
        : process.env.APP_ORIGIN || process.env.BLITZ_DEV_SERVER_ORIGIN;

export const urlDictionary: { [key in URL]: string } = {
    HOME: '',
    CREATE_PASSWORD: `create-password`,
    FORGOT_PASSWORD: `forgot-password`,
    RESET_PASSWORD: `reset-password`,
    SIGN_UP: `signup`,
    EMAIL_MIGRATION: `migrate/by/email`,
};

const buildUrl = (target: URL, queryParams?: QueryParamsProps) => {
    const targetUrl = urlDictionary[target];
    let urlString = `${appOrigin}/${targetUrl}`;
    if (!queryParams) return urlString;

    let queryParamsString = '';

    for (const [param, paramValue] of Object.entries(queryParams)) {
        const isCorrectQueryParamType = ['string', 'number', 'boolean'].includes(typeof paramValue);
        if (isCorrectQueryParamType) {
            queryParamsString += `&${param}=${paramValue}`;
        }
    }

    const url = `${urlString}${queryParamsString.length > 0 ? `?${queryParamsString.replace('&', '')}` : ''}`;

    return url;
};

export const buildUrlSafe = (relativeUrl: string, queryParams?: QueryParamsProps) => {
    let url = appOrigin
        ? `${appOrigin}/${relativeUrl}`
        : process.env.NODE_ENV === 'production'
        ? `${PORTAL_ORIGIN}/${relativeUrl}`
        : `http://localhost:3000/${relativeUrl}`;

    if (!queryParams) return url;

    let queryParamsString = '';
    for (const [param, paramValue] of Object.entries(queryParams)) {
        const isCorrectQueryParamType = ['string', 'number', 'boolean'].includes(typeof paramValue);
        if (isCorrectQueryParamType) {
            queryParamsString += `&${param}=${paramValue}`;
        }
    }

    return `${url}${queryParamsString.length > 0 ? `?${queryParamsString.replace('&', '')}` : ''}`;
};

export default buildUrl;

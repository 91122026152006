import { Suspense, useState } from 'react';

import { MenuItems } from '../LeftMenu';
import { LoginGroup } from '../login-group';
import { SupportHeaderLink } from '../login-group/SupportHeaderLink';
import { HeaderSearch } from './HeaderSearch';
import { ThemeSwitch } from '../ThemeSwitch';
import dynamic from 'next/dynamic';
import Logo from './Logo';

export interface HeaderProps {
    selectedMenuItem?: MenuItems;
}

const BurgerMenu = dynamic(() => import('../burger-menu/BurgerMenu').then((module) => module.BurgerMenu), {
    ssr: false,
});

export function Header({ selectedMenuItem }: HeaderProps) {
    const [isMenuOpen, setMenuOpen] = useState(false);

    return (
        <header className="header">
            <div className="container header__container">
                <Logo className="header__logo" />
                <HeaderSearch />
                <SupportHeaderLink />
                <div className="header__d-mode">
                    <ThemeSwitch modificator="accent" size="sm" />
                </div>
                <LoginGroup />
                {/* eslint-disable-next-line jsx-a11y/no-noninteractive-element-interactions */}
                <a
                    role="navigation"
                    onClick={() => setMenuOpen(!isMenuOpen)}
                    className={`_n-toggle burger header__burger ${isMenuOpen ? '_active' : ''}`}
                >
                    <div className="burger__icon"></div>
                </a>
            </div>
            <BurgerMenu close={() => setMenuOpen(false)} isOpen={isMenuOpen} selectedMenuItem={selectedMenuItem} />
        </header>
    );
}

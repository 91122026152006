import React from 'react';
import UserCard from '../UserCard';
import getUsers from 'app/users/queries/getUsers';
import { PromiseReturnType } from 'blitz';

interface PeopleListProps {
    users: PromiseReturnType<typeof getUsers>['users'];
    isMentorshipDescription?: boolean;
}

const FIRST_NOT_LAZY_LOADING_PHOTOS = 8;

export function PeopleList({ users, isMentorshipDescription }: PeopleListProps) {
    return (
        <>
            {users.length ? (
                users.map((user, index) => (
                    <UserCard
                        key={user.id}
                        user={user}
                        isImgLazyLoading={index >= FIRST_NOT_LAZY_LOADING_PHOTOS}
                        isMentorshipDescription={isMentorshipDescription}
                    />
                ))
            ) : (
                <div className="col-12">No people were found that match the selected filters.</div>
            )}
        </>
    );
}

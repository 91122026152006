export default function AllTagsIcon() {
    return (
        <svg
            className="icon icon-all-tags"
            width="26"
            height="21"
            viewBox="0 0 26 21"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
        >
            <path
                d="M12.5763 1L14 3.2549L15.4237 1H17.5593H20L14 11L8 1H10.4407H12.5763Z"
                strokeWidth="2"
                strokeLinejoin="round"
            />
            <path d="M20.5 10L16 12.5V17.5L20.5 20L25 17.5V12.5L20.5 10Z" strokeWidth="2" strokeLinejoin="round" />
            <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M2.88194 17.2811C2.61352 18.3388 2.35923 20.1964 3.4894 20.8201C4.61958 21.4439 6.18769 20.3049 6.99294 19.5456C7.19072 19.7218 7.3885 19.8845 7.57215 20.0337C8.23612 20.5354 8.87185 20.8608 9.43693 20.9557C9.80424 21.0235 10.1715 20.9829 10.4965 20.8066C11.6266 20.1828 11.3724 18.3116 11.1039 17.2676C12.1917 16.9693 14 16.237 14 14.9896C14 13.7421 12.2059 13.0235 11.1322 12.7252C11.4006 11.6675 11.6549 9.80987 10.5247 9.18614C10.1998 9.00987 9.81837 8.95563 9.46519 9.03699C8.56105 9.1997 7.6004 9.91835 7.02119 10.4607C6.20182 9.71496 4.64783 8.5624 3.50353 9.18614C2.37336 9.80987 2.62765 11.6811 2.89606 12.7252C1.80827 13.0235 0 13.7421 0 15.0031C0 16.2642 1.79415 16.9693 2.88194 17.2811ZM4 15L4.73967 13.695L5.5 12.4019L7 12.39L8.5 12.4019L9.26033 13.695L10 15L9.26033 16.305L8.5 17.5981L7 17.61L5.5 17.5981L4.73967 16.305L4 15ZM7 16C7.55228 16 8 15.5523 8 15C8 14.4477 7.55228 14 7 14C6.44772 14 6 14.4477 6 15C6 15.5523 6.44772 16 7 16Z"
                fill="currentColor"
            />
        </svg>
    );
}

type DebouncedFunction = (...args: any[]) => void;

export const DEFAULT_DEBOUNCE_TIME_MS = 500;

export default function debounce(fn: DebouncedFunction, timeInMillis = DEFAULT_DEBOUNCE_TIME_MS): DebouncedFunction {
    let timeout: NodeJS.Timeout | null;

    return function (this: any, ...args: any[]) {
        const context = this;

        if (timeout) {
            clearTimeout(timeout);
        }

        timeout = setTimeout(() => {
            fn.apply(context, args);

            timeout = null;
        }, timeInMillis);
    };
}

export function debounceAsync(fn, timeInMillis = 500) {
    let timeout;
    let resolvePrev;

    return async function (...args) {
        if (timeout) {
            clearTimeout(timeout);
            if (resolvePrev) {
                resolvePrev();
            }
        }

        return new Promise((resolve, reject) => {
            resolvePrev = resolve;

            timeout = setTimeout(() => {
                fn(...args)
                    .then(resolve)
                    .catch(reject);
            }, timeInMillis);
        });
    };
}

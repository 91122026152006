export default function MoonIcon() {
    return (
        <svg
            className="icon icon-moon"
            xmlns="http://www.w3.org/2000/svg"
            width="16"
            height="16"
            viewBox="0 0 16 16"
            fill="none"
        >
            <path d="M14.2656 10.5157L15.1935 10.8885C15.3429 10.5168 15.256 10.0918 14.9727 9.80855C14.6895 9.52527 14.2645 9.43839 13.8928 9.58778L14.2656 10.5157ZM5.48436 1.73438L6.41223 2.10726C6.56162 1.73554 6.47474 1.31054 6.19146 1.02727C5.90819 0.743991 5.48319 0.657115 5.11147 0.806498L5.48436 1.73438ZM13.8928 9.58778C13.2319 9.85337 12.5093 10 11.75 10V12C12.7691 12 13.7445 11.8028 14.6385 11.4435L13.8928 9.58778ZM11.75 10C8.57436 10 6 7.42565 6 4.25001H4C4 8.53022 7.46979 12 11.75 12V10ZM6 4.25001C6 3.49073 6.14665 2.76814 6.41223 2.10726L4.55648 1.36149C4.19721 2.25548 4 3.23094 4 4.25001H6ZM2.25 8.00001C2.25 5.5834 3.74113 3.51266 5.85724 2.66225L5.11147 0.806498C2.26359 1.95098 0.25 4.73914 0.25 8.00001H2.25ZM8 13.75C4.82436 13.75 2.25 11.1757 2.25 8.00001H0.25C0.25 12.2802 3.71979 15.75 8 15.75V13.75ZM13.3378 10.1428C12.4874 12.2589 10.4166 13.75 8 13.75V15.75C11.2609 15.75 14.049 13.7364 15.1935 10.8885L13.3378 10.1428Z" />
        </svg>
    );
}

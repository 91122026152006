import React, { createContext, useCallback } from 'react';
import { useMutation } from '@blitzjs/rpc';
import updateUserInterests from 'app/users/mutations/updateUserInterests';
import { Tag } from '@gn/db/client';
import { ContextUser, setUser } from '../CurrentUserContext';
import debounce from '@gn/core/utils/debounce';

type ToggleTagSubscription =
    | ((tag: Pick<Tag, 'id'>, user: ContextUser, debounced?: boolean) => Promise<void>)
    | undefined
    | null;
export const ToggleTagSubscriptionContext = createContext<ToggleTagSubscription>(undefined);

export function ToggleTagSubscriptionProvider({ children }) {
    const [updateUserInterestsMutation] = useMutation(updateUserInterests);

    const saveUserInterestsDebounced = useCallback(debounce(updateUserInterestsMutation, 2000), [
        updateUserInterestsMutation,
    ]);

    const toggleTagSubscription = useCallback(
        async (tag: Pick<Tag, 'id'>, user: ContextUser, debounced = true) => {
            if (!user) {
                return;
            }

            const isSubscribed = user.interests.some((interest) => interest.id === tag.id);
            const newInterests = isSubscribed
                ? user.interests.filter((tagI) => tagI.id !== tag.id)
                : [tag, ...user?.interests];

            setUser(
                {
                    ...user,
                    interests: newInterests,
                },
                { refetch: false },
            );

            const saveInterests = debounced ? saveUserInterestsDebounced : updateUserInterestsMutation;

            return saveInterests({
                data: {
                    interests: newInterests.map((tagI) => tagI.id).join(','),
                },
            });
        },
        [saveUserInterestsDebounced, updateUserInterestsMutation],
    );

    return (
        <ToggleTagSubscriptionContext.Provider value={toggleTagSubscription}>
            {children}
        </ToggleTagSubscriptionContext.Provider>
    );
}

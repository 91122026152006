export default function PlusIcon() {
    return (
        <svg
            className="icon icon-plus"
            width="14"
            height="14"
            viewBox="0 0 14 14"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
        >
            <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M8.16526 1.16667C8.16526 0.522334 7.64293 0 6.9986 0C6.35427 0 5.83193 0.522334 5.83193 1.16667V5.83334H1.16667C0.522334 5.83334 0 6.35567 0 7.00001C0 7.64434 0.522335 8.16667 1.16667 8.16667H5.83193V12.8333C5.83193 13.4777 6.35427 14 6.9986 14C7.64293 14 8.16526 13.4777 8.16526 12.8333V8.16667H12.8333C13.4777 8.16667 14 7.64434 14 7.00001C14 6.35567 13.4777 5.83334 12.8333 5.83334H8.16526V1.16667Z"
            />
        </svg>
    );
}

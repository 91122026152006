import React, { createContext, useContext, useEffect, useReducer } from 'react';

import { IntercomProvider, useIntercom } from 'react-use-intercom';

const INITIAL_VALUE = {
    launchIntercom: () => {
        return;
    },
    isPlaceholderHidden: false,
    isIntercomOpened: false,
    isLaunched: false,
};

export const IntercomLauncherProviderContext = createContext(INITIAL_VALUE);

type State = {
    isPlaceholderHidden: boolean;
    isLaunched: boolean;
    isIntercomOpened: boolean;
};

type Action =
    | {
          type: 'hide_placeholder';
      }
    | {
          type: 'launch_intercom';
      }
    | { type: 'open_intercom' }
    | { type: 'close_intercom' };

function intercomReducer(state: State, action: Action): State {
    switch (action.type) {
        case 'hide_placeholder':
            return {
                ...state,
                isPlaceholderHidden: true,
            };
        case 'launch_intercom':
            return {
                ...state,
                isLaunched: true,
            };
        case 'open_intercom':
            return {
                ...state,
                isIntercomOpened: true,
            };
        case 'close_intercom':
            return {
                ...state,
                isIntercomOpened: false,
            };
        default:
            return state;
    }
}

const REDUCER_INITIAL_STATE = { isPlaceholderHidden: false, isLaunched: false, isIntercomOpened: false };

export function IntercomLauncherProvider({ children }) {
    const [state, dispatch] = useReducer(intercomReducer, REDUCER_INITIAL_STATE);

    const launchIntercom = () => {
        dispatch({ type: 'launch_intercom' });
        dispatch({ type: 'open_intercom' });
    };

    return (
        <IntercomLauncherProviderContext.Provider
            value={{
                launchIntercom,
                isPlaceholderHidden: state.isPlaceholderHidden,
                isIntercomOpened: state.isIntercomOpened,
                isLaunched: state.isLaunched,
            }}
        >
            {children}
            {state.isLaunched && (
                <IntercomProvider
                    autoBoot
                    appId="c18hida3"
                    onHide={() => dispatch({ type: 'close_intercom' })}
                    onShow={() => dispatch({ type: 'hide_placeholder' })}
                >
                    <ShowIntercom />
                </IntercomProvider>
            )}
            <IntercomLauncher />
        </IntercomLauncherProviderContext.Provider>
    );
}

export const useIntercomLauncherProviderContext = () => useContext(IntercomLauncherProviderContext);

function ShowIntercom() {
    const { show } = useIntercom();
    const { isIntercomOpened } = useIntercomLauncherProviderContext();

    useEffect(() => {
        if (isIntercomOpened) {
            show();
        }
    }, [isIntercomOpened, show]);

    return null;
}

function IntercomLauncher() {
    const { isPlaceholderHidden, launchIntercom, isLaunched } = useIntercomLauncherProviderContext();

    return (
        <>
            {!isPlaceholderHidden && (
                <div className="intercom-lightweight-app">
                    <button
                        className="intercom-lightweight-app-launcher"
                        aria-label="Open Intercom Messenger"
                        aria-live="polite"
                        onClick={launchIntercom}
                    >
                        <div className="intercom-lightweight-app-launcher-icon intercom-lightweight-app-launcher-icon-open">
                            {isLaunched ? (
                                <svg
                                    xmlns="http://www.w3.org/2000/svg"
                                    xmlnsXlink="http://www.w3.org/1999/xlink"
                                    style={{ margin: 'auto', display: 'block', width: '48px', height: '48px' }}
                                    width="48px"
                                    height="48px"
                                    viewBox="0 0 100 100"
                                    preserveAspectRatio="xMidYMid"
                                >
                                    <circle
                                        cx="50"
                                        cy="50"
                                        r="32"
                                        stroke-width="8"
                                        stroke="#fff"
                                        stroke-dasharray="50.26548245743669 50.26548245743669"
                                        fill="none"
                                        stroke-linecap="round"
                                    >
                                        <animateTransform
                                            attributeName="transform"
                                            type="rotate"
                                            repeatCount="indefinite"
                                            dur="1s"
                                            keyTimes="0;1"
                                            values="0 50 50;360 50 50"
                                        />
                                    </circle>
                                </svg>
                            ) : (
                                <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 28 32">
                                    <path d="M28 32s-4.714-1.855-8.527-3.34H3.437C1.54 28.66 0 27.026 0 25.013V3.644C0 1.633 1.54 0 3.437 0h21.125c1.898 0 3.437 1.632 3.437 3.645v18.404H28V32zm-4.139-11.982a.88.88 0 00-1.292-.105c-.03.026-3.015 2.681-8.57 2.681-5.486 0-8.517-2.636-8.571-2.684a.88.88 0 00-1.29.107 1.01 1.01 0 00-.219.708.992.992 0 00.318.664c.142.128 3.537 3.15 9.762 3.15 6.226 0 9.621-3.022 9.763-3.15a.992.992 0 00.317-.664 1.01 1.01 0 00-.218-.707z" />
                                </svg>
                            )}
                        </div>
                    </button>
                    <style
                        id="intercom-lightweight-app-style"
                        type="text/css"
                        dangerouslySetInnerHTML={{
                            __html: `@keyframes intercom-lightweight-app-launcher{from{opacity:0;transform:scale(.5)}to{opacity:1;transform:scale(1)}}.intercom-lightweight-app{position:fixed;z-index:29;width:0;height:0;font-family:intercom-font,"Helvetica Neue","Apple Color Emoji",Helvetica,Arial,sans-serif}.intercom-lightweight-app-launcher{position:fixed;z-index:2147483003;padding:0!important;margin:0!important;border:none;bottom:20px;right:20px;max-width:48px;width:48px;max-height:48px;height:48px;border-radius:50%;background:#f60;cursor:pointer;box-shadow:0 1px 6px 0 rgba(0,0,0,.06),0 2px 32px 0 rgba(0,0,0,.16);transition:transform 167ms cubic-bezier(.33, 0, 0, 1);box-sizing:content-box}.intercom-lightweight-app-launcher:hover{transition:transform 250ms cubic-bezier(.33, 0, 0, 1);transform:scale(1.1)}.intercom-lightweight-app-launcher:active{transform:scale(.85);transition:transform 134ms cubic-bezier(.45, 0, .2, 1)}.intercom-lightweight-app-launcher:focus{outline:0}.intercom-lightweight-app-launcher-icon{display:flex;align-items:center;justify-content:center;position:absolute;top:0;left:0;width:48px;height:48px;transition:transform .1s linear,opacity 80ms linear}.intercom-lightweight-app-launcher-icon-open{opacity:1;transform:rotate(0) scale(1)}.intercom-lightweight-app-launcher-icon-open svg{width:24px;height:24px}.intercom-lightweight-app-launcher-icon-open svg path{fill:rgb(255,255,255)}@media print{.intercom-lightweight-app{display:none}}`,
                        }}
                    />
                </div>
            )}
        </>
    );
}

export default function ArrowLeft() {
    return (
        <svg
            className="icon icon-arrow-left"
            width="16"
            height="14"
            viewBox="0 0 16 14"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
        >
            <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M7.7071 13.7071C7.31658 14.0976 6.68341 14.0976 6.29289 13.7071L0.292889 7.70711C-0.0976366 7.31658 -0.0976365 6.68342 0.292889 6.29289L6.29289 0.292894C6.68342 -0.0976313 7.31658 -0.0976313 7.7071 0.292894C8.09763 0.683418 8.09763 1.31658 7.7071 1.70711L3.41421 6L15 6C15.5523 6 16 6.44772 16 7C16 7.55229 15.5523 8 15 8L3.41421 8L7.7071 12.2929C8.09763 12.6834 8.09763 13.3166 7.7071 13.7071Z"
            ></path>
        </svg>
    );
}

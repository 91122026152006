import Link from 'next/link';

import { trackEvent } from 'app/analytics';
import { useIntercomLauncherProviderContext } from '../IntercomLauncherProvider';

export function SupportHeaderLink() {
    const { launchIntercom } = useIntercomLauncherProviderContext();

    return (
        <div className="header__menu hidden-md">
            <ul className="main-menu">
                <li className="main-menu__item">
                    <Link href="/faq" className="main-menu__link">
                        FAQ
                    </Link>
                </li>
                <li className="main-menu__item">
                    <a
                        href="#"
                        onClick={(e) => {
                            e.preventDefault();
                            trackEvent({
                                action: 'click-header-support',
                            });
                            launchIntercom();
                            return false;
                        }}
                        className="main-menu__link"
                    >
                        Support/Feedback
                    </a>
                </li>
                <li className="main-menu__item">
                    <Link href="/javascript-developers-statistics" className="main-menu__link">
                        Nation`s stats
                    </Link>
                </li>
            </ul>
        </div>
    );
}

import { Mentorship, MentorshipStatus, Tag, User } from '@gn/db/client';
import { getAvatar, getProfileUrl, getUserCountryAndCompany } from 'app/users/utils';
import { getUserNameWithFallback } from '@gn/core/user/utils';
import classNames from 'classnames';
import Link from 'next/link';
import { useRouter } from 'next/router';
import type { PropsWithChildren, ReactNode } from 'react';
import { TagComponent } from './tags-list/TagComponent';

interface UserCardProps {
    user: Pick<User, 'company' | 'location' | 'avatar' | 'id' | 'nickname' | 'name' | 'bio'> & {
        mentorship?: Pick<Mentorship, 'status' | 'description'> | null;
    } & { interests: Pick<Tag, 'label' | 'id' | 'slug'>[] };
    isImgLazyLoading?: boolean;
    className?: string;
    isMentorshipDescription?: boolean;
}

export default function UserCard({
    user,
    isImgLazyLoading,
    className = 'row-margin__item col-xxl-3 col-xl-4 col-sm-6',
    children,
    isMentorshipDescription,
}: PropsWithChildren<UserCardProps>) {
    const router = useRouter();
    const showMentorshipDescription =
        (router.query.showOnlyMentors === 'true' || isMentorshipDescription) && user.mentorship?.description;
    const hasInterests = !!user.interests?.length;
    const userName = getUserNameWithFallback(user);

    return (
        <div className={className}>
            <div className="profile-item">
                <Link
                    href={getProfileUrl(user)}
                    className="profile-item__link-cover"
                    aria-label={`${userName} profile`}
                />

                <div className="profile-item__main">
                    <div className="profile-item__img">
                        <img
                            src={getAvatar(user)}
                            alt={user.name || 'user avatar'}
                            loading={isImgLazyLoading ? 'lazy' : undefined}
                            //@ts-ignore
                            fetchpriority={isImgLazyLoading ? undefined : 'high'}
                        />
                        <span className="status status--lg"></span>
                    </div>
                    <div className="profile-item__name">{userName}</div>
                    <div className="profile-item__cat">{getUserCountryAndCompany(user)}</div>
                    {user.mentorship && user.mentorship.status === MentorshipStatus.Enabled ? (
                        <div className="profile-item__mentorship label label--mentorship">Mentorship available</div>
                    ) : null}

                    <div className="profile-item__descr">
                        {showMentorshipDescription ? user.mentorship?.description : user.bio}
                    </div>
                </div>

                {hasInterests && (
                    <div className="profile-item__bottom">
                        <UserDetail
                            className="tags-list"
                            label="Interests"
                            value={user.interests.map((tag) => (
                                <TagComponent key={tag.id} selectedTag={null} tag={tag} />
                            ))}
                        />
                    </div>
                )}

                {children}
            </div>
        </div>
    );
}

const UserDetail = ({ label, value, className }: { label: string; value: ReactNode; className?: string }) => {
    return (
        <div className={classNames('pi-details', className)}>
            <div className="pi-details__label">{label}:</div>
            <div className="pi-details__labeled">{value}</div>
        </div>
    );
};

import classNames from 'classnames';
import { useState } from 'react';
import { UnmountClosed } from 'react-collapse';

interface FilterSectionProps {
    classname?: string;
    children?: React.ReactNode;
}

export const FilterSection: React.FC<FilterSectionProps> = ({ classname, children }) => {
    return children ? (
        <div className={classNames('aside-block', classname)}>
            <div className="aside-title">Filters</div>
            <div className="aside-filter">{children}</div>
        </div>
    ) : null;
};

export const MobileFilterSection: React.FC<FilterSectionProps> = ({ children }) => {
    const [isOpen, setOpen] = useState(false);

    const toggleFilters = () => {
        setOpen((prev) => !prev);
    };

    return (
        <div className="aside-block visible-md">
            <div className="aside-block__head justify-content-center">
                <button className="btn btn--md" onClick={toggleFilters}>
                    {isOpen ? 'Hide filters' : 'Show filters'}
                </button>
            </div>
            <UnmountClosed
                isOpened={isOpen}
                theme={{ collapse: 'aside-filter__collapse', content: 'aside-filter__content' }}
            >
                {children}
            </UnmountClosed>
        </div>
    );
};

import * as React from 'react';

const FlagIcon = () => (
    <svg
        className="icon icon-flag"
        xmlns="http://www.w3.org/2000/svg"
        width="24"
        height="24"
        viewBox="0 0 24 24"
        fill="none"
    >
        <path d="M4 21V5.5" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" />
        <path
            d="M4 14.5262C9.81818 9.42091 14.1818 19.6315 20 14.5262V5.47378C14.1818 10.5791 9.81818 0.368466 4 5.47378"
            strokeWidth="2"
            strokeLinecap="round"
            strokeLinejoin="round"
        />
    </svg>
);

export default FlagIcon;

import Link from 'next/link';

interface MultipassCardProps {
    asLink?: boolean;
}

export const MultipassCard = ({ asLink }: MultipassCardProps) => {
    const content = (
        <>
            <div className="card-multipass__head">
                <div className="cm-wrap">
                    <div className="cm-wrap__img">
                        <img src="/img/logo-dark.svg" alt="GitNation Logo" />
                    </div>
                    <div className="cm-wrap__info">
                        <span className="cm-wrap__title">
                            Korben <br />
                            Dallas
                        </span>
                        <span className="cm-wrap__text">visa@gitnation.org</span>
                    </div>
                </div>
            </div>
            <div className="card-multipass__title">Want to have access to all events for 4x less?</div>
        </>
    );
    return asLink ? (
        <Link href="/multipass" className="card-multipass">
            {content}
        </Link>
    ) : (
        <div className="card-multipass">
            {content}
            <div className="card-multipass__btn">
                <Link href="/multipass" className="btn btn--sm btn--default">
                    Learn more
                </Link>
            </div>
        </div>
    );
};

import { useRouter } from 'next/router';
import { SyntheticEvent, useCallback, useContext } from 'react';

import { CurrentUserContext } from '../components/CurrentUserContext';

const useAuthenticated = (callback: (event?: SyntheticEvent) => Promise<void>, redirectTo?: string) => {
    const router = useRouter();
    const user = useContext(CurrentUserContext);

    const withAuthentication = useCallback(
        async (event?: SyntheticEvent) => {
            if (!user) {
                event?.preventDefault();
                router.push(`/login?return-to=${redirectTo ?? router.asPath}`);
                return;
            }

            await callback(event);
        },
        [user, callback],
    );

    return withAuthentication;
};

export default useAuthenticated;

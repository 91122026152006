export default function ArrowRight() {
    return (
        <svg
            className="icon icon-arrow-right"
            width="16"
            height="14"
            viewBox="0 0 16 14"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
        >
            <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M8.2929 0.292893C8.68342 -0.0976311 9.31659 -0.0976311 9.70711 0.292893L15.7071 6.29289C16.0976 6.68342 16.0976 7.31658 15.7071 7.70711L9.70711 13.7071C9.31659 14.0976 8.68342 14.0976 8.2929 13.7071C7.90237 13.3166 7.90237 12.6834 8.2929 12.2929L12.5858 8L1 8C0.447716 8 0 7.55228 0 7C0 6.44771 0.447716 6 1 6H12.5858L8.2929 1.70711C7.90237 1.31658 7.90237 0.683417 8.2929 0.292893Z"
            ></path>
        </svg>
    );
}

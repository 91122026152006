export default function SunIcon() {
    return (
        <svg
            className="icon icon-sun"
            xmlns="http://www.w3.org/2000/svg"
            width="16"
            height="16"
            viewBox="0 0 16 16"
            fill="none"
        >
            <path
                d="M8 1.25V2M8 14V14.75M14.75 8H14M2 8H1.25M12.773 12.773L12.2426 12.2426M3.75736 3.75736L3.22703 3.22703M12.773 3.22707L12.2427 3.7574M3.7574 12.2427L3.22707 12.773M11 8C11 9.65685 9.65685 11 8 11C6.34315 11 5 9.65685 5 8C5 6.34315 6.34315 5 8 5C9.65685 5 11 6.34315 11 8Z"
                strokeWidth="2"
                strokeLinecap="round"
                strokeLinejoin="round"
            />
        </svg>
    );
}

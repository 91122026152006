import Socials from './Socials';

export default function FollowUs() {
    return (
        <div className="follow-us ">
            <div className="follow-us__title">Follow us</div>
            <Socials />
        </div>
    );
}

import { DEFAULT_DEBOUNCE_TIME_MS } from './debounce';
import { useState, useEffect } from 'react';

const useDebouncedValue = <T>(value, delay = DEFAULT_DEBOUNCE_TIME_MS) => {
    const [debouncedValue, setDebouncedValue] = useState<T>(value);

    useEffect(() => {
        const timeout = setTimeout(() => {
            setDebouncedValue(value);
        }, delay);

        return () => {
            clearTimeout(timeout);
        };
    }, [value, delay]);

    return debouncedValue;
};

export default useDebouncedValue;

import { AttendanceFormat, Event } from '@gn/db/client';
import { getFullTicket } from '@gn/core/content/utils/client';
import { isMultipassValid } from '@gn/core/multipass/utils';
import { useContext, useMemo } from 'react';
import max from 'date-fns/max';

import { CurrentUserContext } from '../components/CurrentUserContext';

export type EventAccess = {
    hasAccess: boolean;
    type?: 'ticket' | 'multipass' | null;
    reason?: 'multipass_expires' | null;
    ticket?: { name: string; format?: AttendanceFormat | null };
};

export default function useEventAccess(event: Pick<Event, 'id'>, date?: Date | null) {
    const user = useContext(CurrentUserContext);

    const access = useMemo((): EventAccess => {
        if (!user) {
            return { hasAccess: false };
        }

        const fullTicket = getFullTicket(user, event.id);
        if (fullTicket) {
            return { hasAccess: true, type: 'ticket', ticket: { name: fullTicket.ticket, format: fullTicket.format } };
        }

        const hasMultipass = isMultipassValid(user);
        if (hasMultipass) {
            const hasMultipassOnDate = !date || isMultipassValid(user, max([date, new Date()]));

            if (hasMultipassOnDate) {
                return { hasAccess: true, type: 'multipass' };
            }
            return { hasAccess: false, reason: 'multipass_expires' };
        }

        return { hasAccess: false };
    }, [user, event]);

    return access;
}

import { useQuery } from '@blitzjs/rpc';
import Head from 'next/head';
import React, { memo } from 'react';

import getContentChaptersSemantic from 'app/contents/queries/getContentChaptersSemantic';
import getUsers from 'app/users/queries/getUsers';
import { ContentList } from './content-list/ContentList';
import { PeopleList } from './people-list/PeopleList';
import { ErrorBoundary } from '@blitzjs/next';
import Loader from './loader';
import { PromiseReturnType } from 'blitz';
import getTags from 'app/tags/queries/getTags';
import getEvents from '../../events/queries/getEvents';
import { EventListItem } from './EventsList';
import { Prisma } from '@gn/db/client';
import { getTagSearchWhere } from '../../tags/utils';
import TagListItem from './tags-list/TagListItem';
import getTagsWithContentCount from 'app/tags/queries/getTagsWithContentCount';

interface ISearchPage {
    searchTerm: string;
    selectedTags?: NonNullable<PromiseReturnType<typeof getTags>>['tags'];
}

interface SearchContentProps {
    events;
    contents;
    tags: NonNullable<PromiseReturnType<typeof getTagsWithContentCount>>;
    searchTerm;
    users;
    selectedTags?: NonNullable<PromiseReturnType<typeof getTags>>['tags'];
}

export function SearchContent({ events, contents, tags, searchTerm, users, selectedTags }: SearchContentProps) {
    return (
        <div className="wrap__content">
            <div className="page-main">
                <div className="search-main">
                    <div className="page-main__head">
                        <h1 className="page-main__title">Search results for "{searchTerm}":</h1>
                    </div>
                    <div className="articles--white-box">
                        {!!tags?.length && (
                            <div className="row">
                                {tags.map((tag) => (
                                    <TagListItem key={tag.id} tag={tag} className="tag-box--background-grey" />
                                ))}
                            </div>
                        )}
                        {!!events?.length && (
                            <div className="row events-list events-list--smiles-bg">
                                {events.map((event) => (
                                    <div key={event.id} className="col-xxl-3 col-xl-4 col-sm-6 row-margin__item">
                                        <EventListItem event={event} />
                                    </div>
                                ))}
                            </div>
                        )}
                        {!!users?.length && (
                            <div className="row">
                                <PeopleList users={users} />
                            </div>
                        )}
                        {!!contents?.length && <ContentList contents={contents} />}
                        {!events?.length && !users?.length && !contents?.length && <h2>Nothing found</h2>}
                    </div>
                </div>
            </div>
        </div>
    );
}

function SearchPageContentComponent({ searchTerm }: ISearchPage) {
    // search results to exclude the event brand info as these requests are slower

    const [eventsQuery, { isLoading: isLoadingEvents }] = useQuery(getEvents, {
        where: {
            hideFromList: false,
            AND: searchTerm.split(' ').map((word) => ({
                name: {
                    contains: word,
                    mode: 'insensitive' as Prisma.QueryMode,
                },
            })),
        },
        orderBy: {
            startDate: 'desc',
        },
    });
    const events = eventsQuery?.events;

    const [tags, { isLoading: isLoadingTags }] = useQuery(getTagsWithContentCount, {
        where: getTagSearchWhere(searchTerm),
    });

    const [contents, { isLoading: isLoadingContents }] = useQuery(getContentChaptersSemantic, { searchTerm });

    const [usersQuery, { isLoading: isLoadingUsers }] = useQuery(getUsers, {
        where: {
            name: {
                contains: searchTerm,
                mode: 'insensitive',
            },
            role: 'user',
        },
    });

    return (
        <>
            {isLoadingEvents || isLoadingContents || isLoadingTags || isLoadingUsers ? (
                <div className="wrap__content">
                    <Loader />
                </div>
            ) : (
                <>
                    <Head>
                        <title>{`Search results for: ${searchTerm}`}</title>
                    </Head>

                    <SearchContent
                        events={events}
                        contents={contents}
                        tags={tags}
                        users={usersQuery?.users}
                        searchTerm={searchTerm}
                    />
                </>
            )}
        </>
    );
}

export const SearchPageContent = memo(SearchPageContentComponent, (prev, next) => {
    return prev.searchTerm === next.searchTerm;
});

export function SearchPage({ searchTerm }: ISearchPage) {
    return (
        // TODO: add error component
        <ErrorBoundary FallbackComponent={() => null}>
            <SearchPageContent searchTerm={searchTerm} />
        </ErrorBoundary>
    );
}

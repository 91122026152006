import { useTheme } from 'next-themes';
import React from 'react';
import { trackEvent } from 'app/analytics';
import classNames from 'classnames';
import SunIcon from './icons/SunIcon';
import MoonIcon from './icons/MoonIcon';

export function ThemeSwitch({ size, modificator }: { size?: 'sm'; modificator?: 'accent' }) {
    const { resolvedTheme, setTheme, theme } = useTheme();

    const themeSwitchToggle = () => {
        resolvedTheme == 'light' ? setTheme('dark') : setTheme('light');
    };

    return (
        <button
            className={classNames('switch-theme', {
                'switch-theme--sm': size === 'sm',
                'switch-theme--accent': modificator === 'accent',
            })}
            onClick={() => {
                themeSwitchToggle();
                trackEvent({
                    action: 'click-change-theme',
                });
            }}
            aria-label="theme toggle"
        >
            <span>
                <SunIcon />
            </span>
            <span>
                <MoonIcon />
            </span>
        </button>
    );
}

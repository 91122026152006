import { SearchBlock } from './SearchBlock';
import Socials from './Socials';
import { ThemeSwitch } from './ThemeSwitch';
import ArrowDownIcon from './icons/ArrowDownIcon';
import PlanetIcon from './icons/PlanetIcon';
import Link from 'next/link';
import { ReactNode, useState } from 'react';
import cn from 'classnames';
import { trackEvent } from 'app/analytics';
import { useIntercomLauncherProviderContext } from './IntercomLauncherProvider';

const FooterMenu = ({ title, children }: { title: string; children: ReactNode }) => {
    const [spoiler, setSpoiler] = useState(false);
    return (
        <div className="footer__item">
            <div className={cn('footer__title', { 'is-active': spoiler })} onClick={() => setSpoiler(!spoiler)}>
                {title}
                <ArrowDownIcon />
            </div>
            <div className="footer__dropdown">{children}</div>
        </div>
    );
};
export function Footer() {
    const { launchIntercom } = useIntercomLauncherProviderContext();
    return (
        <footer className="footer">
            <div className="container">
                <div className="footer__wrap">
                    <div className="footer__side">
                        <Link href="/" className="logo footer__logo" aria-label="GitNation home page">
                            <div style={{ width: '26px', height: '33px' }}>
                                <svg
                                    xmlns="http://www.w3.org/2000/svg"
                                    width="26"
                                    height="33"
                                    viewBox="0 0 26 33"
                                    fill="none"
                                >
                                    <path
                                        d="M13.1401 24.7717C11.8701 24.7717 10.8367 23.7562 10.8279 22.4976V22.4889V22.4195H8.47193V22.4889C8.50696 25.0407 10.6265 27.0805 13.2014 27.0457C15.7237 27.011 17.7644 24.9887 17.7994 22.4889V22.4195H15.4435C15.4522 22.4455 15.4522 22.4629 15.4522 22.4889C15.4522 23.7475 14.4188 24.7717 13.1401 24.7717ZM8.61206 9.73855C6.75532 9.73855 5.2489 11.2314 5.2489 13.0715C5.2489 14.9116 6.75532 16.4045 8.61206 16.4045C10.4688 16.4045 11.9752 14.9116 11.9752 13.0715C11.9752 11.2314 10.4688 9.73855 8.61206 9.73855ZM7.54356 13.0802C7.54356 12.4987 8.0165 12.0213 8.6033 12.0126C9.1901 12.0126 9.67181 12.4813 9.68056 13.0628C9.68056 13.6444 9.20762 14.1218 8.62082 14.1304C8.02526 14.1304 7.55232 13.6617 7.54356 13.0802ZM2.95425 7.19542C9.42658 5.16438 11.8964 3.89716 13.0087 3.27222C14.121 3.89716 16.582 5.16438 23.0631 7.19542C23.0631 7.92451 23.4748 30.0836 13.0087 30.0662C2.54261 30.0749 2.94549 7.92451 2.95425 7.19542ZM13.0087 33C26.5314 33.0347 25.9971 5.99763 25.9971 5.06891C17.6243 2.43898 14.4363 0.798526 13.0087 0C11.5636 0.789847 8.38435 2.4303 0.0027296 5.06891C0.0027296 5.99763 -0.522762 33.0347 13.0087 33ZM14.0947 11.8737V14.1131H20.6021V11.8737H14.0947Z"
                                        fill="var(--colorWhiteToDanger)"
                                    />
                                </svg>
                            </div>
                            <span>GitNation</span>
                        </Link>
                    </div>
                    <div className="footer__content">
                        <div className="footer__row">
                            <FooterMenu title="Company">
                                <ul className="f-nav">
                                    <li className="f-nav__item">
                                        <a href="https://gitnation.org" className="f-nav__link" target="_blank">
                                            About us
                                        </a>
                                    </li>
                                    <li className="f-nav__item">
                                        <a
                                            href="https://jobs.gohire.io/gitnation-2ntrzg06/"
                                            className="f-nav__link"
                                            target="_blank"
                                        >
                                            Careers
                                        </a>
                                    </li>
                                    {/* <li className="f-nav__item">
                                        <a href="#" className="f-nav__link">
                                            Partners
                                        </a>
                                    </li> */}
                                    <li className="f-nav__item">
                                        <a href="mailto:events@gitnation.org" className="f-nav__link">
                                            Contact us
                                        </a>
                                    </li>
                                </ul>
                            </FooterMenu>
                            <FooterMenu title="Assistance">
                                <ul className="f-nav">
                                    <li className="f-nav__item">
                                        <Link href="/faq" className="f-nav__link">
                                            FAQ
                                        </Link>
                                    </li>
                                    <li className="f-nav__item">
                                        <a
                                            href="#"
                                            className="f-nav__link"
                                            onClick={(e) => {
                                                e.preventDefault();
                                                trackEvent({
                                                    action: 'click-footer-support',
                                                });
                                                launchIntercom();
                                                return false;
                                            }}
                                        >
                                            Support/Feedback
                                        </a>
                                    </li>
                                    <li className="f-nav__item">
                                        <Link href="/rss-feeds" className="f-nav__link">
                                            RSS Feeds
                                        </Link>
                                    </li>
                                </ul>
                            </FooterMenu>
                            <FooterMenu title="Legal">
                                <ul className="f-nav">
                                    <li className="f-nav__item">
                                        <a href="https://gitnation.org/terms" className="f-nav__link" target="_blank">
                                            Terms & Conditions
                                        </a>
                                    </li>
                                    <li className="f-nav__item">
                                        <a
                                            href="https://gitnation.org/data-promise"
                                            className="f-nav__link"
                                            target="_blank"
                                        >
                                            Privacy Policy
                                        </a>
                                    </li>
                                    <li className="f-nav__item">
                                        <Link href="/coc" className="f-nav__link">
                                            Code Of Conduct
                                        </Link>
                                    </li>
                                </ul>
                            </FooterMenu>

                            <div className="footer__community">
                                <div className="footer__title hidden-sm">Community</div>
                                <Socials type="circle" />
                            </div>
                        </div>
                        <SearchBlock className="search-wrap--sm" />

                        <div className="footer__bottom">
                            <div className="copyright">&copy; 2024 GitNation. All rights reserved.</div>
                            <ul className="f-bottom-nav">
                                <li>
                                    <Link href="/javascript-developers-statistics" className="f-bottom-nav__link">
                                        <PlanetIcon />
                                        Nation`s stats
                                    </Link>
                                </li>
                            </ul>
                            <div className="footer__toggler">
                                <ThemeSwitch />
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </footer>
    );
}

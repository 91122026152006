import Link from 'next/link';
import { useRouter } from 'next/router';

export function LoginHeaderButton() {
    const router = useRouter();
    const returnTo = router.query['return-to'];

    return (
        <div className="header__us-navbar us-navbar hidden-md">
            <div className="us-navbar__el">
                <Link
                    href={{
                        pathname: '/login',
                        query: {
                            'return-to': returnTo || router.asPath,
                        },
                    }}
                    className="btn btn--light btn--sm"
                    rel="nofollow"
                >
                    Log in
                </Link>
            </div>
        </div>
    );
}
